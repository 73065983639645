// 0.2 UTILITY COLOR
$white: #ffffff;
$black: #000000;
$blue:#448eef;
$blue1:#307fe2;
$red:#dc3030;

$blue-grey:  #24274f;  
$border-primary: rgba(255,255,255,0.05);
$border-secondary: rgba(255,255,255,0.1);
$bg-primary: #283045;
$bg-primary-dark : #21283B;
$helper-text:#627085;
$paragraph-text:#8596a9;
$iconic-btn-color:#8596a9;  
$iconic-btn-bg:#2c354f;

$th-color:#788192;
$th-bg:rgba(5, 123, 248, .1);
$td-text:#ccd9e8;

$gradient-primary: linear-gradient(125deg, #5a6688 0%, #2662A3 100%);
$gradient-reverse-primary: linear-gradient(125deg, #2662A3 0%, #5a6688 100%);

$card-shadow: 0px 5px 15px rgba(22,22,35,0.1);
$card-dark-bg: -webkit-gradient(linear, left top, left bottom, from(#323c58), to(#2a324a));
$card-dark-border: #323b58;

$overlay-bg: rgba(33, 40, 59, .7) !important;