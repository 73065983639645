/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
// 0.1 FONTS
// 0.2 FONT WEIGHT
// 0.3 FONT FAMILY
// 0.4 BREAKPOINTS
// 0.5 ASSET PATH VARIABLES
--------------------------------------------------------------*/

// 0.1 FONTS
$base-font           :  14px;
$large-font          :  $base-font + 4;  // ~18px
$medium-font         :  $base-font + 2;  // ~16px
$small-font          :  $base-font - 2;  // ~12px
$extra-small-font    :  $base-font - 4;  // ~10px

// 0.3 FONT FAMILY
$opneSans: 'Open Sans', sans-serif;

// 0.4 BREAKPOINTS
$desktop-large-width        :   1920px;
$desktop-medium-width       :   1366px;
$desktop-small-width        :   1280px;
$tablet-landscape           :   1024px;
$tablet-width               :   991px;
$mobile-width               :   767px;
$mobile-width-small         :   480px;
$mobile-mini                :   320px;

// 0.5 ASSET PATH VARIABLES
$assetPath: "/assets/images";
//$spriteSheet: "../images/sprite-sheet.svg";