/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
0.1 ICON RESET STYELS

--------------------------------------------------------------*/
// 0.1 ICON RESET STYELS

.icon22 {
    width: 22px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    &.add-comment-tc {
        background: url(#{$assetPath}/summary-icons/add-comment.svg) no-repeat center;
        background-size: -1px -1px; 
    }
}