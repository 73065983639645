// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
@import "./_vendor/vendor";
@import "./_modules/variables";
@import "./_modules/mixins";
@import "./_modules/colors";
@import "./_modules/icons";
@import "./_partials/common";
@import "./_partials/footer";
@import "./_partials/header";
@import "./_vendor/fonts";
@import "../../node_modules/ngx-spinner/animations/ball-spin-clockwise-fade-rotating.css";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tml-admin-primary: mat.define-palette(mat.$deep-purple-palette, 400);
$tml-admin-accent: mat.define-palette(mat.$orange-palette, 400, A400, A200);

// The warn palette is optional (defaults to red).
$tml-admin-warn: mat.define-palette(mat.$deep-orange-palette);

// Create the theme object (a Sass map containing all of the palettes).
$tml-admin-theme: mat.define-light-theme(
  $tml-admin-primary,
  $tml-admin-accent,
  $tml-admin-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tml-admin-theme);

/* You can add global styles to this file, and also import other style files */

// 0.2 HTML GENERIC CSS
body {
  // font-family: "Roboto", sans-serif;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 400;
  line-height: normal;
  font-size: $base-font;
  margin: 0;
  color: #666c84; //$white;
  overflow: -moz-scrollbars-vertical;
  overflow-x: hidden;
  overflow-y: hidden;
}

// Anchor link styles
a,
button {
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}
a {
  color: $blue;
  text-decoration: none;
}
// list styls
ul {
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    display: inline-block;
    list-style: none;
  }
}

input {
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }
}
.cdk-overlay-pane .mat-menu-panel {
  min-height: auto;
}

.filter-container .md-drppicker {
  width: 500px !important;
  left: auto !important;
  right: 0 !important;
}

.swal2-popup .swal2-content {
  font-size: 0.8rem !important;
  font-weight: 600 !important;
}
.swal2-popup {
  width: 30em !important;
  position: relative !important;
  flex-direction: column !important;
  justify-content: center !important;
  max-width: 100% !important;
  padding: 1.25em !important;
  background: #fff !important;
  font-family: inherit !important;
  font-size: 0.75rem !important;
  box-sizing: border-box !important;
}

@for $i from 1 through 100 {
  .w#{$i} {
    width: ($i) * 1% !important;
  }
}

.Tribe-Management-7 {
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #27292f;
  line-height: 2;
  padding: 0 10px;
}

.trbl {
  margin: 0 5px 0 5px;
  height: 80vh;
}

.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.mat-footer-cell {
  color: rgba(0, 0, 0, 0.54);
}
