/* 0.1 : CONTAINER */
/* 0.2 : LOGIN WINDOW */
/* 0.3 : MAIN WRAPPER */

// 0.1 CONTAINER
.container {
  @include desktop-large {
    max-width: 1600px;
  }
}

// 0.2 LOGIN WINDOW
.sign-in-card {
  .mat-form-field {
    &.mat-focused {
      .mat-form-field-label {
        color: $blue;
      }
    }

    input {
      color: $white;
    }

    &.pb-0 {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-gap,
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-color: $blue;
  }

  .mat-form-field-label {
    color: $blue;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $red;
  }

  button {
    &.btn-link {
      margin-bottom: 15px;
      font-size: 12px;
    }
  }
}

// 0.3 MAIN WRAPPER
.wrapper-with-bg {
  padding: 5px;
  background: $gradient-primary;
  height: 100vh;

  .ev-dashboard-wrapper {
    background-color: $bg-primary;
    @include desktop-medium {
      margin-top: 0;
      margin-bottom: 0;
      height: calc(100vh - 10px);
    }
    height: calc(100vh - 10px);
    @include border-radius(8px);
    padding: 0;
    header {
      height: 60px;
      border-bottom: 1px solid $border-primary;
      .logo-panel + .mat-button{
          min-width: auto;
          padding: 0;
      }
      .head-section {
        padding: 15px 20px;
        height: 60px;
        background-color: $blue-grey;


        a {
          color: $white;
        }
        .logo-panel {
          width: 220px;
         // background-color: $bg-primary-dark;
          background-color: $blue-grey;
          margin: 0 0 0 -20px;
          height: 60px;
          padding: 0 20px;
          // border-right: 1px solid $border-primary;
          // border-bottom: 1px solid $border-primary;
          @include border-radius(6px 0 0 0);
          @include desktop-medium {
            width: 280px;
          }
          img {
            width: 100px;
            height: auto;
          }
        }
      }

      +div {
        height: calc(100% - 60px);
      }
    }

    .navigation {
      width: 220px; //300px;
      border-right: 1px solid $border-primary;
      height: 100%;
      @include desktop-medium {
        width: 250px;
      }
      .navigation-list {
          padding: 0 0 0;
          display: flex;
          flex-direction: column;
          height: 100%;
          background-color: $blue-grey;
        li {
          display: block;
          a {
            font-weight: 500;
            display: flex;
            align-items: center;
            padding: 12px 18px;
            margin: 0 0 0 0;
            color: $white;
            // @include border-radius(20px 0 0 20px);
            transition: all .3s;
            i{
                padding-right: 10px;
                color: rgba(255, 255, 255, 0.5);
                font-size: 20px;
                transition: all .3s;
            }
            .block-entry{
                text-align: center;
                line-height: 25px;
                font-size: 12px;
                border-radius: 20px 0 0 20px;
                padding: 0 10px 0 10px;
                text-align: right;
                margin-right: -18px;
                transition: all 0.3s;
                font-weight: 600;
                color: rgba(255,255,255,.5);
                &.tribe-entry{
                    background: #548d96;
                }
                &.member-entry{
                    background: #a55971;
                }
            }
            &:hover {
              background: $blue1;
              padding-left: 15px;
              .block-entry{
                background: #fff;
                color: $blue1;
              }
              i{
                color: $white;
              }
            }
            &.active {
              background: $blue1;
              .block-entry{
                background: #fff;
                color: $blue1;
              }
              i{
                color: $white;
              }
            }
          }
          &.create-section {
            margin-top: auto;
            margin-bottom: 0;
          }
        }
      }
    }

    .widget-wrapper {
      flex-grow: 1;
      padding:0;
      height: auto;
      // overflow: auto;
      max-width: calc(100% - 220px);
      background-color: #f1f1f1;
      > ng-scrollbar > div{
        > .ps-content{
           // padding: 30px 30px 0;
        }
        // .ps__thumb-y{
        //     margin-top:30px;
        // }
      }
      @include desktop-medium {
        max-width: calc(100% - 250px);
        padding: 10px;
      }
    }
  }
  ul.tribe-list{
      li{
          display: flex;
      }
  }
  .card{
    @include border-radius(0px);
    width: 100%;
  &.card-custom{
      margin-bottom: 0px;
      // border: 1px solid $card-dark-border;
      background-color: #F1F1F1;
      // background-image: $card-dark-bg;
      box-shadow: $card-shadow;
      position: relative;
      // height: calc(100vh - 100px);
      .img-container{
          border-radius: 6px;
          overflow: hidden;
          img{
              width: 100%;
          }
      }
  }
    &.card-tribe {
        margin-top: 10px;
        margin-bottom: 5px;
        //border: 1px solid $card-dark-border;
        background-color: #ffffff;  //transparent;
      //  background-image: $card-dark-bg;
        box-shadow: $card-shadow;
        position: relative;
        border-radius: 6px;
        .card-body{
           // padding: 15px 30px 30px; //30px
            display: flex;
            flex-direction: column;
            @include desktop-medium {
                padding: 20px;
              }
            .card-title{
                color: #27292f;
                font-weight: 500;
                margin-bottom: 15px;
                font-size: 21px;
                font-weight: bold;
                @include desktop-medium {
                    font-size: 18px;
                }
            }
            p.card-text{
                color: $paragraph-text;
                font-weight: 400;
                //margin-bottom: 30px;
                line-height: 20px;
               // text-align: justify;
                @include desktop-medium {
                    margin-bottom: 20px;
                }
            }
        }
        .img-container{
            // width: auto;
            // height: 160px;
            box-shadow: none !important;
            overflow: hidden;
            transition: all .3s;
            // overflow: hidden;
           // margin: -10px 30px 10px;
            @include border-radius(6px 6px 0 0);
           // box-shadow: 0 5px 10px #272f45;
            background-color: #b0b0b038;
            height: 120px;
            @include desktop-medium {
                margin: -10px 20px 10px;
                height: 100px;
            }
            .default-tribe{
                height: 100%;
            }
            img{
              width: 100%;
              height: 100%;
              // width:100%;
              object-fit: cover;
              // height: 100%;
            }
        }
        .button-container{
           // background: $bg-primary;
            @include border-radius(50px);
           // padding: 6px;
            button{
                cursor: pointer;
                background: none;
                /* border: 1px solid #272f45; */
                //margin-right: 6px;
               // background: $iconic-btn-bg;
                width: 34px;
                height:34px;
                transition: all .5s;
                i{
                    font-size: 18px;
                    transition: all .4s;
                    color: $iconic-btn-color;
                }
                &:hover{
                    // box-shadow: 0 0px 20px 0px #245dbb91;
                   // background-color: $bg-primary;
                    i{
                        transform: scale(1.4);
                        color: $blue1;
                    }
                }
            }
            .mat-checkbox{
                > label{
                    margin-bottom: 0;
                }
            }
        }
        &:hover{
            .img-container{
                margin: 0;
                @include border-radius(6px 6px 0 0);
                box-shadow: none;
            }
        }
        &.active-tribe:after{
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            right: 30px;
            height: 4px;
            background: $blue1;
            left: 30px;
        }

        .mat-checkbox{
            label{
                .mat-checkbox-inner-container{
                    width:40px;
                    height:40px;
                    .mat-checkbox-frame{
                        background: $iconic-btn-bg;
                        border: 0;

                        &:hover{
                            background: rgba(0, 0, 0, 0.15);
                        }
                    }
                    .mat-checkbox-background, .mat-checkbox-frame{
                        border-radius: 50%;
                    }
                }
            }
            .mat-checkbox-checkmark {
              transform: scale(0.6);
          }
            &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{
                background: $blue1 !important;
            }
            &.mat-checkbox-checked.mat-accent{
                .mat-checkbox-checkmark {
                    // transform: scale(0.6);
                }
                .mat-checkbox-background{
                    background-color:$blue1;
                    svg{
                      &.mat-checkbox-checkmark{
                        fill: transparent !important;

                      }
                        .mat-checkbox-checkmark-path {
                            stroke: $bg-primary !important;
                            stroke-width: 4px !important;
                        }
                    }
                }
            }
        }
        .mat-form-field-appearance-outline {
          .mat-form-field-outline{
              opacity: 1 !important;
              &.mat-form-field-outline-thick{
                  opacity: 0 !important;
              }
              .mat-form-field-outline-end,.mat-form-field-outline-gap,.mat-form-field-outline-start{
                  border-color:red !important;
              }
          }
        }
        .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
          border-top-color: transparent !important;
        }
    }
  }
  .helper-text{
    font-size: 12px;
    color: $helper-text;
}
}

.dark-mat-menu{
    //background: linear-gradient(to right, #5a6688 0%, #2662A3 100%) !important;
    background: linear-gradient(to right, #24274f 0%, #24274f 100%) !important;
    padding: 20px;
    box-shadow: 0px 5px 10px rgba(0,0,0,.2) !important;
    &.profile-mat-menu{
        padding: 8px 20px 20px;
        margin-top: -47px;
        margin-right: -17px;
        border-radius: 6px;
        min-width: 230px;
        position: relative;
        overflow: hidden;
        .img-container{
            margin-right: 20px;
        }
        .logged-in-usr-header{
            color: #fff;
            padding-bottom: 8px;
            margin-bottom: 15px;
            border-bottom: 1px solid rgba(255,255,255,.09);
            z-index: 1;
            position: relative;
            h4{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
            }
            p{
                margin: 0;
                text-transform: uppercase;
                font-size: 11px;
                color:rgba(255, 255, 255, 0.6);
            }

        }
        .navigation-list {
          li {
            display: block;
            a {
              font-weight: 500;
              display: flex;
              align-items: center;
              padding: 12px 0;
              color: $white;
              @include border-radius(4px);
              border-bottom: 1px solid rgba(255,255,255,.05);
              transition: all .3s;
              i{
                  padding-right: 10px;
                  color: rgba(255, 255, 255, 0.5);
                  font-size: 20px;
                  transition: all .3s;
              }
              &:hover {
                background: rgba(255, 255, 255, 0.1);
                padding-left: 10px;
                padding-right: 6px;
                i{
                  color: $white;
                }
              }
            }
          }
        }
    }
    .mat-menu-content{
        &:not(:empty){
            padding-top: 0;
        }
        > i{
            position: absolute;
            right: -34px;
            bottom: -27px;
            font-size: 140px;
            color: rgba(0, 0, 0, 0.1);
        }
    }
}

.mat-tab-group{
    font-family: "Quicksand", sans-serif !important;
    .mat-tab-header{
        //background: $bg-primary-dark;
        border-color: transparent;
        @include border-radius(6px 6px 0 0);
        color: $blue;
    }
    .mat-tab-body{
        //padding: 30px;
        .element-header{
            border-bottom: 1px solid $border-primary;;
            position: relative;
            padding: 0 0 10px;
            &:after{
                content: "";
                background-color: $blue1;
                width: 25px;
                height: 4px;
                border-radius: 0px;
                display: block;
                position: absolute;
                bottom: -3px;
                left: 0px;
            }
        }
        h3{
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 0;
            line-height: 1.2;
        }
        p{
            font-size: 16px;
            font-weight: 300;
            &.paragraph-text{
                margin: 30px 0 30px;
            }
        }
        .helper-text{
            font-size: 14px;
            margin-top: 20px;
        }
        .models{
           // background: #272f45;
           color: #fff;
            border-radius: 6px;
            padding: 20px;
            label{
                text-transform: uppercase;
                font-size: 12px;
                color: #7f89a2;
                margin-bottom: 10px;
            }
            .model-name{
                color: $white;
                position: relative;
                font-weight: 600;
                padding-left: 10px;
                &:before{
                   content: '';
                   display: block;
                   width:5px;
                   height:5px;
                   left:0;
                   top:6px;
                   background: $blue1;
                   position: absolute;
                }
            }
        }
        .floating-btn {
          position: absolute;
          bottom: 20px;
          right: 20px;
        }
        .tab-content-wrapper {
          &.gallery-section {
              padding-bottom: 15px !important;
              padding-top: 15px !important;
            .image-card {
              margin-bottom: 15px;
              margin-top: 15px;
              position: relative;
              &:hover {
                .mat-mini-fab {
                  display: block;
                  @include transition(0.5s all ease);
                }
              }
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                // border: 0.5px solid #d1d1d1;
                @include border-radius(4px);
              }
              .mat-mini-fab {
                position: absolute;
                //top: 15px;
                bottom: 10px;
                right: 15px;
                display: none;
              }
            }
          }
          &.feed-section {
            .feed-container {
              margin-bottom: 30px;
              background-color: #fff;
              box-shadow: $card-shadow;
              position: relative;
              @include border-radius(6px);
              img {
                &.feed-image {
                  height: 250px !important;
                  width: 100%;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                }
              }
              p{
                &.feed-text {
                  color: #27292F;
                  font-size: 16px;
                  font-weight: bold;
                  padding: 20px;
                  background-color: #fff;
                  margin-bottom: -2px;
                }
              }
              .post-data {
                span {
                  &.post-time {
                    font-size: 14px;
                    color: $helper-text;
                  }
                  &.badge {
                    font-size: 14px;
                    color: $white;
                    + .badge {
                      margin-left: 20px;
                    }
                  }
                }
              }
            }
            .comment-container {
              display: block;
              background: #fff;
              li {
                display: block;
                padding: 10px 15px;
                border: 1px solid $border-primary;
                margin-bottom: 10px;
                margin-top: 10px;
                border-radius: 0px;
                color: #FFFFFF;
                background: #F6F6F6;
                p {
                  margin-bottom: 0;
                  color: #27292F;
                  font-weight: bold;
                }
                small {
                  color: #666C84;
                }
              }
            }
          }
          &.badges-section {
            .badge-category-wrapper {
              margin-bottom: 30px;
              h1 {
                font-size: 18px;
                font-weight: bold;
                color: #27292f;
                margin-bottom: 20px;
              }
              .badge-item-wrapper {
                background-color: $white; //$overlay-bg;
                text-align: left;
                height: 100%;
                display: flex;
                flex-direction: column;
                border-radius: 6px;
                box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
                .badge-title {
                  font-size: 16px;
                  color: $white;
                  margin: 5px 20px;
                }
                .badges-value {
                  text-align: left;
                  font-size: 14px;
                  color: $white;
                }
                .button-wrapper {
                  margin-top: auto;
                  text-align: right;
                }
                .badge-image{
                  height: 120px;
                  text-align:center;
                  margin-bottom: 10px;
                  background-color: #24274f;
                  border-radius: 6px 6px 0 0;
                  img{
                    object-fit: contain;
                    width: 305px;
                    height: 120px;
                    padding: 20px;
                  }
                }
              }
            }
          }
        }
    }
    .mat-tab-labels{
        padding: 0 30px;
        .mat-tab-label{
            height: auto;
            min-width: auto;
            font-size: 16px;
            color: $white;
            opacity: 1;
            z-index: 1;
            position: relative;
            margin: 20px 0;
            padding: 6px 25px;
            border-radius: 50px;
        }
    }
    &.mat-primary{
        .mat-ink-bar{
            background-color: transparent !important;
            background: #307fe2 !important;
            z-index: 0;
        }
    }

}

.mat-tab-label-container {
  border-bottom: 1px solid $border-primary;
}
.tribe-details-card {
  height: calc(100vh - 160px);
  .mat-tab-body-wrapper {
    height: calc(100vh - 235px);
    .mat-tab-body{
      .tab-content-wrapper  {
        padding: 30px;
      }
    }
  }
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field-appearance-outline .mat-form-field-outline-thick{
  color: $blue1 !important;
}


.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled],
.mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled]{
  background-color: #999;
}


.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple, .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate{
  background-color: #307fe2 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle, .mat-radio-outer-circle{
  border-color: #307fe2 !important;
}
.mat-dialog-container{
  background: $white !important;  //#323b58
  color: $black !important;
}

.mat-dialog-container{
  .mat-form-field-appearance-outline .mat-form-field-outline-end, .mat-pseudo-checkbox, .mat-form-field-appearance-outline .mat-form-field-outline-gap, .mat-form-field-appearance-outline .mat-form-field-outline-start {
      border-color: #9b9fb0; //$border-secondary;
  }
  .mat-select-value{
    color: #fff;
  }

}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #307fe2;
}
.mat-checkbox-frame{
  border-color: #307fe2 !important;
}

.app-version-card .mat-form-field-appearance-outline .mat-form-field-outline-end,
.app-version-card .mat-form-field-appearance-outline .mat-form-field-outline-gap,
.app-version-card .mat-form-field-appearance-outline .mat-form-field-outline-start{
    border-color: rgba(255, 255, 255, 0.1);
}

.mat-select-arrow, .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow{
  color: #24274f !important;//#fff !important;
}
.img-container-car{
  width: 80px ;
  height: 80px;
  img{
    height: 100% !important;
  }
}
.custom-table{
  padding: 20px;
  th,td{
    width:10% !important;
    //border: 1px solid #6a7c9c;
    border-top: 1px solid #f1f1f1;
    padding: 20px;  //10px;
    text-align: left;
    vertical-align: top;
  }
}
.btn-right{
  float: right !important;
}
.no-record{
  margin: 20px 0 0 50px;
}
span.field-title {
  font-size: 16px !important;
  color:#666c84;
  vertical-align: middle;
  width: 70px;
  // font-weight: bold;
}
.md-drppicker .calendar td span{
  color: black !important;
}
.remove-image{
  position: absolute;
  top: 0;
  right: 0;
  background:#e05427fc;
  border-radius: 5px;
  height: 25px;
}
.upload-btn-wrapper.medium{
  height: 150px;
  margin-bottom: 10px;
}
.mat-snack-bar-container {
  color: black !important;
  background: white !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.mat-select-value-text {
  color:  #24274f !important; // #fff !important ;
}

.img-container{
  background-color: #b0b0b038;
}

// Leaderboard Expansion Card
.leader-content{
  .mat-content{
    justify-content: flex-end !important;
  }
  .mat-expansion-indicator::after{
    display: none !important;
  }
  // .mat-expansion-panel-header#mat-expansion-panel-header-0{
  //   .mat-expansion-panel-header{
  //   background: #303A55 !important;
  //   color: #fff !important;
  // }
  .mat-expansion-panel{
    border-radius: 0px !important;
  }
  .mat-expansion-panel-body {
    // background: #303A55 !important;
    // color: #fff !important;
    padding: 0px !important;
  }
}
.mat-dialog-title{
  border: 0 !important;
  margin: -30px -30px 0px !important;
  padding: 14px 30px !important;
  background: linear-gradient(125deg, #ffffff 0%, #ffffff 100%) !important;
  color: #293145 !important;
  font-size: 20px !important;
  font-family: "Quicksand", sans-serif !important;
}
.mat-tab-label-content {
  color: #666C84 !important;
}
.mat-tab-list {
  background: #fff !important;
}
.floating-btn-action {
  .mat-fab {
    .mat-button-wrapper {
        padding: 0px !important;
    }
}
}
.mat-form-field-appearance-outline .mat-form-field-outline
{
  display: none !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
    border-bottom: 1px solid #000 !important;
}
.add-subscription{
  .mat-dialog-container {
      padding: 0px !important;
  }
}
.popup-column-right{
  //color: #fff !important;
  background-color: #24274f !important;
  padding: 67px 30px 30px 30px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .mat-form-field-label, mat-label{
          color:  #cdced5 !important;
  }
 .mat-form-field-appearance-outline .mat-form-field-infix {
      border-bottom: 1px solid #cdced5 !important;
  }
  .mat-input-element{
    color:  #cdced5 !important;
  }

.mat-radio-label{
  color: #cdced5!important;
}
.mat-form-field{
  display: block !important;
}

}
.subscription-dialog{
  margin: 0px !important;
  padding: 0px !important;
  .subscription-wrapper{
    margin: 0px !important;
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 1em 0 0.2em 0 !important;
  }
  }
}


.main-card{
  h3{
    padding: 8px 0px 0px;
    text-align: center;
  }
  .mat-checkbox-inner-container{
    margin: 0px 8px 0px 0px !important;
  }
  .mat-checkbox-label {
    white-space: break-spaces !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: auto !important;
}
}
.profile-vehicle{
  .mat-dialog-content{
    max-height: 70vh !important;
  }
  .mat-dialog-title {
    margin: -20px 0px 0px 0px !important;
  }
}
.top-text{
  .table-container table.mat-table tr td {
     vertical-align: initial !important;
  }
}

.ps-content {
  .tab-content-wrapper {
    .floating-btn {
        display:none;
    }
  }
}
.tribe-details-card {
  mat-tab-body{
  .mat-tab-body-content {
overflow: hidden !important;
}
}
}
