
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
// 0.1 BORDER RADUIS
// 0.2 ANIMATIONS & TRANISITIONS
// 0.3 GENERIC TRANSFORM
// 0.4 ROTATE
// 0.5 FLEXBOX DISPLAY
// 0.6 OPACITY
// 0.7 CALC HEIGHT AND WIDTH BAAED ON VIEWPORT
// 0.8 BOX SHADOW
// 0.9 TEXT OVERFLOW
// 1.0 VERTICAL CENTER
// 1.1 COMMON SHADOW
// 1.2 RESPONSIVE BREAKPOINTS
--------------------------------------------------------------*/

// 0.1 BORDER RADUIS
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
  }
  
  // 0.2 ANIMATIONS & TRANISITIONS
  
  @mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
  }
  
  @mixin translateY($y) {
    -webkit-transform: translateY($y);
    -ms-transform: translateY($y); // IE9 only
    -o-transform: translateY($y);
    transform: translateY($y);
  }
  
  @mixin translateX($x) {
    -webkit-transform: translateX($x);
    -ms-transform: translateX($x); // IE9 only
    -o-transform: translateX($x);
    transform: translateX($x);
  }
  
  @mixin transform-origin ($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
  }
  
  @mixin transition($transition) {
    // -moz-transition:    $transition;
    // -o-transition:      $transition;
    // -webkit-transition: $transition;
    // -ms-transition: $transition;
    // transition:         $transition;
  }
  
  @mixin transition-none($transition) {
    -moz-transition:    $transition !important;
    -o-transition:      $transition !important;
    -webkit-transition: $transition !important;
    transition:         $transition !important;
  }
  
  
  @mixin transition-duration($transition) {
    -moz-transition-duration: $transition;
    -o-transition-duration: $transition;
    -webkit-transition-duration: $transition;
    transition-duration: $transition;
  }
  
  // 0.3 GENERIC TRANSFORM
  @mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
  }
  // 0.3.1 SCALE
  @mixin scale($scale) {
    @include transform(scale($scale));
  }
  // 0.3.2 TRANSLATE
  @mixin translate ($x, $y) {
    @include transform(translate($x, $y));
  }
  // 0.3.3 SKEW
  @mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
  }
  
  // 0.4 ROTATE
  @mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
  }
  
  // 0.5 FLEXBOX DISPLAY
  @mixin flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  
  // 0.5.1 CROSS-AXIS ALIGNMENT
  @mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    align-items: $align;
  }
  
  
  
  // 0.6 OPACITY
  @mixin opacity($value){
    $IEValue: $value*100;
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
    filter: alpha(opacity=$IEValue);
  }
  
  
  //0.7 CALC HEIGHT AND WIDTH BAAED ON VIEWPORT
  @mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
  }
  
  
  //0.8 BOX SHADOW
  @mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -o-box-shadow: $shadow;
    border-collapse: separate;
  }
  
  // 0.8.1 BOX SHADOW NONE
  @mixin box-shadow-none{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }
  
  // 0.9 TEXT OVERFLOW
  @mixin text-overflow{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  // 1.0 VERTICAL CENTER
  @mixin vertical-center() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  // 1.1 COMMON SHADOW
  @mixin common-shadow() {
    -webkit-box-shadow: -1px 1px 10px rgba(152, 152, 152, 0.4);
    box-shadow: -1px 1px 10px rgba(152, 152, 152, 0.4);
    -moz-box-shadow: -1px 1px 10px rgba(152, 152, 152, 0.4);
    -o-box-shadow: -1px 1px 10px rgba(152, 152, 152, 0.4);
    border-collapse: separate;
  }
  
  // 1.2 RESPONSIVE BREAKPOINTS
  
  // ABOVE 1600PX
  @mixin desktop-large{
    @media screen and (max-width: $desktop-large-width) {
      @content;
    }
  }
  // ABOVE 1440PX
  @mixin desktop-medium{
    @media screen and (max-width: $desktop-medium-width) {
      @content;
    }
  }
  // ABOVE 1280PX
  @mixin desktop-small{
    @media screen and (max-width: $desktop-small-width) {
      @content;
    }
  }
  // TABLET LANDSCAPE
  @mixin tab-landscape{
    @media screen and (max-width: $tablet-landscape) {
      @content;
    }
  }
  // TABLET PORTRAIT
  @mixin tablet-width{
    @media screen and (max-width: $tablet-width) {
      @content;
    }
  }
  // MOBILE SCREENS
  @mixin mobile-width{
    @media screen and (max-width: $mobile-width) {
      @content;
    }
  }
  // EXTRA SMALL 
  @mixin mobile-width-small{
    @media screen and (max-width: $mobile-width-small) {
      @content;
    }
  }
  // MOBILE MINI
  @mixin mobile-mini {
    @media screen and (max-width: $mobile-mini) {
      @content;
    }
  }