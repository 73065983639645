button{
    &.btn-link{
        padding: 0;
        line-height: normal;
        color: $blue;
        &:hover{
            color: $blue;
            text-decoration: none;
        }
    }
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: $blue !important;
}
.mat-sort-header-arrow{
    color: $blue !important;
}
.mat-icon-button[disabled][disabled]{
    color: $bg-primary !important;
}
.table-container {
    position: relative;
    min-height: 200px;
    padding: 0 20px 20px;
    // background: $card-dark-bg;
    // border: 1px solid $card-dark-border;
    box-shadow: $card-shadow;
    @include border-radius(6px);
    width: 100%;
    overflow: auto;
    .table-loading-shade {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        @include border-radius(6px);
        z-index: 1;
        display: flex;
        align-items: top;
        justify-content: center;
        mat-spinner{
            top:10%;
            svg{
                transform: scale(0.8)
            }
        }
      }
    
    mat-paginator{
        background: #f1f1f1;  //$bg-primary;
        @include border-radius(0 0 6px 6px);
        border: 1px solid $border-primary;
        border-top: 0;
        color: #24274f;// $blue;
        .mat-paginator-container{
            padding: 0 20px;
        }
        .mat-icon-button{
            width: 30px;
            height: 30px;
            line-height: 25px;
            background: #6c757d;// $bg-primary-dark;
            margin: 0 0 0 8px;
        }
        .mat-paginator-page-size, .mat-paginator-range-label{
            border-right:1px solid $border-primary;
            padding: 5px 12px;
            margin: 0;
        }
        .mat-paginator-range-label{
            border-right:0;
        }
    }
    table.mat-table {
        font-family: "Quicksand", sans-serif;
        background: transparent;
        width: 100%;
        
        tr{
            th,td{
                //border: 1px solid $border-primary;
                padding: 15px;
                vertical-align: initial;
            }
            th{
                // color: $white;
                // background: $th-bg;
                color: #666c84;
                background: #f1f1f1;
                font-size: 14px;
                font-weight: bold;
                .mat-sort-header-button{
                text-transform: capitalize !important;
                font-size: 14px;
                font-weight: bold;
                color:#656b83; //$blue;
                }
            }
            td{
                color:  #666c84; //;$td-text;
                font-size: $base-font;
                background-color: #ffffff;
            }
        }
    }
}

.cdk-overlay-container{
    .cdk-overlay-dark-backdrop{
        background: $overlay-bg;
    }
    .cdk-overlay-pane{
        &.edit-tribe, &.create-feed{
            // position: fixed !important;
            // right: 20px;
            // top: 20px;
            // bottom: 20px;
            @include border-radius(6px);
            @include box-shadow(0 15px 30px 3px $bg-primary-dark);
            // overflow: hidden;
            .mat-dialog-container{
                @include border-radius(6px);
                padding: 30px;
                .mat-dialog-title{
                    border: 0 !important;
                    margin: -30px -30px 30px;
                    padding: 14px 30px;
                    background: $gradient-primary;
                    color: $white;
                    font-size: 20px;
                    font-family: "Quicksand", sans-serif;
                }
            }
        }
    }
}

.mat-radio-group{
    .mat-radio-button{
        margin-right: 15px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.cdk-overlay-container{
.mat-form-field-label, mat-label{
    color:  #666c84// #B2B6BB;   //#949baf;
}

mat-label{
    font-weight: 600;
    font-size: 12px;
}
label mat-label{
    font-weight: normal;
    font-size: 100%;
}
}

.card{
    &.card-dark{
        margin-bottom: 30px;
        // border: 1px solid $card-dark-border;
        // background-color: $card-dark-border;
        border: 1px solid #f1f1f1;
        background-color: #f1f1f1;
        // background-image: $card-dark-bg;
        box-shadow: $card-shadow;
        position: relative;
        .card-header{
            position: absolute;
            margin: -15px 0 0 30px;
            background: $bg-primary-dark;
            border-radius: 6px;
            box-shadow: 0 7px 15px rgba(0,0,0,.2);
            z-index: 1;
            font-weight: 600;
            font-size: 18px;
            color: $white;
            transition: all 0.3s;
            &:after{
                content: "";
                width: 15px;
                height: 3px;
                background: $blue1;
                display: block;
                top: 0;
                position: absolute;
                left: 20px;
                border-radius: 10px;
                transition: all 0.3s;
                // transform: rotate(90deg);
                // transform: translateY(-50%) rotate(90deg);
            }
        }
        .card-body{
            padding: 15px 0px 15px;
            .mat-form-field-appearance-outline .mat-form-field-outline-end,
            .mat-form-field-appearance-outline .mat-form-field-outline-gap,
            .mat-form-field-appearance-outline .mat-form-field-outline-start {
                border-color: #9b9fb0; //$border-secondary;
            }
            .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
                border-top-color: transparent;
            }
            .mat-form-field-label, mat-label{
                color:  #666c84// #949baf;
            }
            
            mat-label{
                font-weight: 600;
                font-size: 12px;
            }
            label mat-label{
                font-weight: normal;
                font-size: 100%;
            }
            .mat-select-value{
                color: $white;
            }
        }
        // &:hover{
        //     .card-header{
        //         margin-top: -8px;
        //         box-shadow: none;
        //     }
        // }
        .card-footer{
            position: absolute;
            bottom: -30px;
            right: 30px;
            padding: 10px;
            border-radius: 6px !important;
            overflow: hidden;
            background: $bg-primary;
            border:1px solid $border-primary;
            .mat-raised-button.mat-primary[disabled] {
                color: #8c8c8c;
                // background-color: $helper-text;
            }
        }
    }
}

.mat-select-panel{
    background: $card-dark-bg !important;
    .mat-option{
        color:  #27292f !important; //rgba(255,255,255,0.7);
        &.mat-active {
            // background: rgba(0, 0, 0, 0.1) !important;
            // color: $white !important;
            background: #f8f9fa !important;
            color: #27292f !important;
            
        }
    }
}

.ev-dashboard-wrapper{
    .mat-form-field-label, mat-label{
        color: #949baf;
        //text-transform: uppercase;
    }

    mat-label{
        font-weight: 600;
        font-size: 12px;
    }
    label mat-label{
        font-weight: normal;
        font-size: 100%;
    }
}

.mat-form-field{
    &.ng-invalid{
        label.mat-form-field-label{
            mat-label{
                font-size: 12px;
            }
        }
    }
}
.upload-btn-wrapper-icon{ 
    height: 80px !important;
    width: 80% !important;
}
.upload-btn-wrapper{
    position: relative;
    width: 100%;
    height: 211px;
    border:1px solid $border-primary;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
    @include border-radius(6px);
    &:hover{
        .upload-btn{
            background: $bg-primary-dark;
        }
    }
    .upload-btn{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        height: 100%;
        background: $blue-grey;
        color: $white;
        font-size: 16px;
        transition: all .3s;
        i{
            margin-bottom: 10px;
            font-size: 40px;
        }
        
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    input[type=file] {
        width: 100%;
        height:100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }
}
.mat-hint{
    color: $helper-text !important;
}

.charcount{
    color: #666c84 !important;
}
div{
    .btn{
        margin-right: 10px;
        &:focus{
            outline: 0 !important;
            box-shadow: none !important;
        }
        &:last-child{
            margin-right: 0;
        }
        &.btn-secondary{
            border:1px solid $blue1;
            color: $blue1;
            background-color: $white;
            @include border-radius(18px);
            &:hover{
                background-color: $white;
                color: $blue1;
            }
        }
        
        &.btn-primary{
            background: $blue1;
            color: $white;
            border:none;
            transition: all .3s;
            min-width: inherit;
            @include border-radius(18px);
        }
    }
}

.btn-secondary:not(:disabled):not(.disabled):active{
    background-color: $white;
    color: $blue1;
}

.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
    background-color: #e7ebef !important;

}